<!-- 推荐课程item -->
<template>
    <div>
        <div class="c-top">
            <div class="cover">
                <img :src="courseInfo.icon"
                     alt="">
                <span v-if="courseInfo.courseType === 1" class="type">测评</span>
                <span v-if="courseInfo.courseType === 2" class="type">训练</span>
            </div>
            <div class="c-content">
                <h3>{{ courseInfo.name }}</h3>
                <div class="ability-box">
                    <div v-for="ability in courseInfo.courseAbilityTags" class="ability">
                        <div :style="`background-color: ${ ability.color }`" class="back"></div>
                        <img :src="ability.icon" alt="">
                        <i :style="`color: ${ ability.color }`">{{ ability.name }}</i>
                    </div>
                </div>
                <div class="c-people">
                    <img src="@/assets/icon/i_boy.png" alt="">
                    {{ courseInfo.minAge }} ~ {{ courseInfo.maxAge }} 岁
                </div>
            </div>
        </div>
        <div class="c-bottom">
            <span v-if="courseInfo.likeInfo">
                <img src="@/assets/icon/i_group.png" alt="">
                {{courseInfo.likeInfo.studyCount}}人在学
            </span>
            <span v-if="courseInfo.likeInfo">
                <img src="@/assets/icon/i_love.png" alt="">
                {{courseInfo.likeInfo.likeCount}}人喜欢
            </span>
            <div class="purchase">
                <van-button class="ant-button" size="mini" round type="primary">
                    ￥{{courseInfo.price}} 去购买
                </van-button>
                <div class="price" v-html="courseInfo.launchHtml">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "recommendCourseItem",
        props: {
            courseInfo: Object
        }
    }
</script>

<style scoped lang="less">
    .c-top {
        display: flex;
    }

    .cover {
        width: 108px;
        height: 140px;
        flex-shrink: 0;
        border-radius: 16px;
        margin-right: 20px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .type {
            padding: 2px 7px;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 12px;
            letter-spacing: 0.1em;
            position: absolute;
            right: 6px;
            bottom: 6px;
        }
    }

    .c-content {
        flex-grow: 1;
    }

    h3 {
        font-size: 14px;
        line-height: 22px;
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        -webkit-box-orient: vertical; /* 垂直排列 */
        word-break: break-all; /* 内容自动换行 */
        margin-bottom: 12px;
    }

    .c-people {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #6A7479;
        margin-top: 15px;
        img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            margin-right: 6px;
        }
    }

    .ability-box {
        height: 22px;
        overflow: hidden;
        margin-bottom: 14px;
    }

    .ability {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 2px 6px;
        border-radius: 8px;
        margin-right: 6px;
        font-size: 10px;
        line-height: 18px;
        position: relative;
        overflow: hidden;

        img {
            width: 10px;
            height: 10px;
            margin-right: 2px;
        }
    }

    .back {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        opacity: 0.24;
    }

    .c-bottom {
        color: #949EA4;
        font-size: 12px;
        position: relative;
        margin-top: 8px;

        span {
            display: inline-flex;
            align-items: center;
            margin-right: 15px;
        }

        img {
            height: 12px;
            display: inline;
            margin-right: 4px;
        }

        .purchase {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .ant-btn {
            background: #FA9E32;
            border: none;
            border-radius: 40px;
        }
    }

    .price {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;

        /deep/ .launch-btn {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            user-select: none;
        }
    }
</style>