export default class SoreChart {
    constructor(obj) {
        if (typeof obj.number !== "number" || !obj.number){
            obj.number = 0;
        }
        this.canvas = document.getElementById(obj.id);
        this.canvas.width = this.canvas.offsetWidth * 2;
        this.canvas.height = this.canvas.offsetHeight * 2;
        this.ctx = this.canvas.getContext("2d");
        this.init(obj);
        this.initArcBack();
        this.setArcSpeed(0);
        let n = 0;
        const that = this;
        this.timer = setInterval(function () {
            n += 5;
            if (n > obj.number){
                n = obj.number;
            }
            that.setArcSpeed.call(that, n);
            if (n >= obj.number) {
                n = obj.number;
                that.setArc(n);
                clearInterval(that.timer);
            }
        }, 20);
    }

    init(obj) {
        this.paddingTop = 0;
        this.paddingBottom = 0;
        this.paddingLeft = 0;
        this.paddingRight = 0;
        this.arcWidth = obj.arcWidth || 32;
        this.soreBack = obj.soreBack || "#FEF3DE";
        this.soreColor = obj.soreColor || "#FBD986";
        this.roundColor = obj.roundColor || "#F5B03E";
        if (typeof obj.padding === "number") {
            this.paddingTop = obj.padding;
            this.paddingBottom = obj.padding;
            this.paddingLeft = obj.padding;
            this.paddingRight = obj.padding;
        }
        if (Object.prototype.toString.call(obj.padding) === "[object Array]") {
            this.paddingTop = obj.padding[0];
            this.paddingRight = obj.padding[1];
            this.paddingBottom = obj.padding[2];
            this.paddingLeft = obj.padding[3];
        }
    }

    initArcBack() {
        this.ctx.strokeStyle = this.soreBack;
        this.ctx.fillStyle = this.soreBack;
        const r1 = this.arcWidth;
        const w = this.canvas.width - this.paddingLeft - this.paddingRight,
            h = this.canvas.height - this.paddingBottom - this.paddingTop,
            y = this.canvas.height - this.paddingBottom - r1;
        const r = w / 2 > h ? h / 2 : w / 2;
        this.ctx.beginPath();
        this.ctx.moveTo(this.canvas.width / 2 - (r - r1 * 2), y);
        this.ctx.arc(this.canvas.width / 2 - (r - r1 * 1.5), y, r1 / 2, 0, Math.PI);
        this.ctx.arc(this.canvas.width / 2, y, r - r1, Math.PI, 2 * Math.PI);
        this.ctx.arc(this.canvas.width - r1 * 1.5, y, r1 / 2, 0, Math.PI);
        this.ctx.arc(this.canvas.width / 2, y, r - r1 * 2, 2 * Math.PI, Math.PI, true);
        this.ctx.stroke();
        this.ctx.fill();
        this.ctx.closePath();
    }

    setArcSpeed(number) {
        if (typeof number !== "number") {
            return
        }
        const n = number / 100;
        const r1 = this.arcWidth;
        const w = this.canvas.width - this.paddingLeft - this.paddingRight,
            h = this.canvas.height - this.paddingBottom - this.paddingTop,
            y = this.canvas.height - this.paddingBottom - r1;
        const r = w / 2 > h ? h / 2 : w / 2;
        const arcTo = {
            x: Math.cos((1 + n) * Math.PI) * (r - r1 * 1.5) + this.canvas.width / 2,
            y: Math.sin((1 + n) * Math.PI) * (r - r1 * 1.5) + y
        };
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.soreColor;
        this.ctx.fillStyle = this.soreColor;
        this.ctx.moveTo(this.canvas.width / 2 - (r - r1 * 2), y);
        this.ctx.arc(this.canvas.width / 2 - (r - r1 * 1.5), y, r1 / 2, 0, Math.PI);
        this.ctx.arc(this.canvas.width / 2, y, r - r1, Math.PI, (1 + n) * Math.PI);
        this.ctx.arc(arcTo.x, arcTo.y, r1 / 2, (1 + n) * Math.PI, n * Math.PI);
        this.ctx.arc(this.canvas.width / 2, y, r - r1 * 2, (1 + n) * Math.PI, Math.PI, true);
        this.ctx.stroke();
        this.ctx.fill();
        this.ctx.closePath();
    }

    setArc(number){
        if (typeof number !== "number") {
            return
        }
        const n = number / 100;
        const r1 = this.arcWidth;
        const w = this.canvas.width - this.paddingLeft - this.paddingRight,
            h = this.canvas.height - this.paddingBottom - this.paddingTop,
            y = this.canvas.height - this.paddingBottom - r1;
        const r = w / 2 > h ? h / 2 : w / 2;
        const arcTo = {
            x: Math.cos((1 + n) * Math.PI) * (r - r1 * 1.5) + this.canvas.width / 2,
            y: Math.sin((1 + n) * Math.PI) * (r - r1 * 1.5) + y
        };

        this.ctx.beginPath();
        this.ctx.fillStyle = this.roundColor;
        this.ctx.arc(arcTo.x, arcTo.y, r1 / 2 + 8, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.closePath();
    }
}