<!--能力分析-->
<template>
    <m-loading color="#1989fa" :loading="spinning">
        <div class="ability-box" :class="abilityIcon[activeAbility].className">
            <div class="ability-icon">
                <ul :style="`width: ${abilityIcon.length * 56}px`">
                    <li :class="activeAbility === index && 'active'"
                        @click="abilityClick(index)"
                        v-for="(item, index) in abilityIcon">
                        <div :style="activeAbility === index && `border-color: ${item.color};`">
                            <img :src="item.icon" alt="">
                        </div>
                        <p>{{ item.name }}</p>
                    </li>
                </ul>
            </div>


            <div class="score">
                <div class="char">
                    <canvas id="char-box"></canvas>
                    <div class="char-info">
                        <p>得分</p>
                        <h3>{{ analysisData.abilityScore }}</h3>
                        <span v-if="(abilityIcon[activeAbility].score - abilityIcon[activeAbility].firstScore > 0)">
                            <img src="@/assets/icon/i_up.png" alt="">{{ abilityIcon[activeAbility].score - abilityIcon[activeAbility].firstScore }}
                        </span>
                    </div>
                </div>
                <div class="text">{{ analysisData.description }}</div>
            </div>

            <van-tabs default-active-key="1" swipeable class="tabs" size="small">
                <van-tab key="1" title="能力表现">
                    <div class="tab-cont">
                        <h2>能力排名</h2>
                        <div class="normal">
                            <div style="position: relative;">
                                <img class="normal-char" :src="abilityIcon[activeAbility].normalImg" alt="">
                                <div :style="`left: ${(analysisData.abilityScore - 50)}%;`" class="line">
                                    <img src="@/assets/academic/aca-girl.png" alt="">
                                    <div :class="(analysisData.abilityScore - 50) > 50 && 'left'" class="info">
                                        超过全国 {{ Math.floor(analysisData.performance.abilityScorePercentage) || 0 }}% 同龄人
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li v-for="text in normalText"
                                    :style="`background: ${abilityIcon[activeAbility].color};`">
                                    {{ text }}
                                </li>
                                <li :style="`left: ${(analysisData.abilityScore - 50)}%;`" class="triangle"></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-cont">
                        <h2>评语</h2>
                        <div class="cont">
                            {{ analysisData.performance.remark }}
                        </div>
                    </div>
                </van-tab>
                <van-tab key="2" title="维度分析">
                    <div v-if="analysisData.dimensionals.length >= 3" class="tab-cont">
                        <h2>维度数据</h2>
                        <div class="radar">
                            <div class="radar-box" ref="radar"></div>
                            <div class="radar-text text1">超过全国 90% 同龄人</div>
                            <div class="radar-text text2">超过全国 90% 同龄人</div>
                            <div class="radar-text text3">超过全国 90% 同龄人</div>
                        </div>
                    </div>
                    <div class="tab-cont detailed-analysis">
                        <h2>详细分析</h2>
                        <van-swipe>
                            <van-swipe-item class="carousel-item" v-for="(item, index) in analysisData.dimensionals" :key="item.abilityName">
                                <div class="semantics">
                                    <h3>{{ item.abilityName }}
                                        <van-popover v-model="showPopover['item'+ index]" trigger="click" theme="dark">
                                            <p style="padding: 10px;max-width: 200px;font-size: 12px;">
                                                {{ item.abilityDescription }}
                                            </p>
                                            <template #reference>
                                                <van-icon name="warning-o"/>
                                            </template>
                                        </van-popover>
                                    </h3>
                                    <div class="progress">
                                        <div class="line">
                                            <div :style="`width: ${item.score - 50}%`"></div>
                                        </div>
                                        <span>{{ item.score }}/150</span>
                                    </div>
                                </div>
                                <div class="cont">
                                    {{ item.remark }}
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </van-tab>
                <van-tab key="3" title="发展建议">
                    <div class="tab-cont">
                        <h2>参考建议</h2>
                        <div class="cont">
                            {{ analysisData.suggestion }}
                        </div>
                    </div>
                    <div v-if="course.length">
                        <h2>推荐课程</h2>
                        <div>
                            <RecommendCourseItem style="margin-top: 16px;" v-for="(courseCont, index) in course" :course-info="courseCont"
                                                 :key="index"/>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </m-loading>
</template>

<script>
import Apis from "@/requests/api";
import SoreChart from "@/config/soreChart"
import RecommendCourseItem from "./components/RecommendCourseItem"

export default {
    name: "AbilityAnalysis",
    components: {RecommendCourseItem},
    data() {
        return {
            spinning: false,
            showPopover: {
                item1: false,
                item2: false,
                item3: false
            },
            userId: this.$route.query.userId,
            activeAbility: 0,
            normalText: ["较差", "及格", "中等", "良好", "优秀"],
            abilityIcon: [
                {
                    icon: require("@/assets/academic/fiveAbilityIcon/sw-icon.png"),
                    normalImg: require("@/assets/academic/normal/zt-sw.png"),
                    color: "#4285f4",
                    name: "思维力",
                    className: "sw",
                    colors: ["rgba(66, 133, 244, 0.3)", "rgba(66, 133, 244, 0.5)", "rgba(66, 133, 244, 1)"]
                },
                {
                    icon: require("@/assets/academic/fiveAbilityIcon/jy-cion.png"),
                    normalImg: require("@/assets/academic/normal/zt-jy.png"),
                    color: "#34a853",
                    name: "记忆力",
                    className: "jy",
                    colors: ["rgba(52, 168, 83, 0.3)", "rgba(52, 168, 83, 0.5)", "rgba(52, 168, 83, 1)"]
                },
                {
                    icon: require("@/assets/academic/fiveAbilityIcon/zy-icon.png"),
                    normalImg: require("@/assets/academic/normal/zt-zy.png"),
                    color: "#fbbc05",
                    name: "注意力",
                    className: "zy",
                    colors: ["rgba(251, 188, 5, 0.3)", "rgba(251, 188, 5, 0.5)", "rgba(251, 188, 5, 1)"]
                },
                {
                    icon: require("@/assets/academic/fiveAbilityIcon/fy-icon.png"),
                    normalImg: require("@/assets/academic/normal/zt-fy.png"),
                    color: "#b61285",
                    name: "反应力",
                    className: "fy",
                    colors: ["rgba(182, 18, 133, 0.3)", "rgba(182, 18, 133, 0.5)", "rgba(182, 18, 133, 1)"]
                },
                {
                    icon: require("@/assets/academic/fiveAbilityIcon/zk-icon.png"),
                    normalImg: require("@/assets/academic/normal/zt-zk.png"),
                    color: "#ea4335",
                    name: "自控力",
                    className: "zk",
                    colors: ["rgba(234, 67, 53, 0.3)", "rgba(234, 67, 53, 0.5)", "rgba(234, 67, 53, 1)"]
                }
            ],
            radarOption: {},
            analysisData: {
                performance: {},
                dimensionals: []
            },
            course: []
        }
    },
    mounted() {
        document.title = "能力分析";
        this.getAbilityPortraitData();
        this.initRadar();
    },
    methods: {
        getAbilityPortraitData(userCourseId) {
            this.spinning = true;
            this.$http.get(Apis.Kindergarten.getUserCourseAbilityPortrait, {
                organizationUserId: this.$route.query.organizationUserId,
                userCourseId: this.$route.query.userCourseId,
            }).then(res => {
                if (res.status) {
                    if (!res.data) {
                        return
                    }
                    const abilityList = res.data.abilityScoreInfo;
                    this.abilityIcon = abilityList.filter(f => this.abilityIcon.some(s => s.name === f.name))
                        .map((m, i) => {
                            if (m.name === this.$route.query.type) {
                                this.activeAbility = i;
                            }
                            let ability = this.abilityIcon.filter(f => f.name === m.name)[0];
                            return {
                                ...ability,
                                ...m
                            }
                        });
                    this.getAnalysisData();
                } else {
                    this.$toast(res.message);
                }
            })
        },
        getAnalysisData() {
            this.spinning = true;
            this.$http.get(Apis.Kindergarten.getAnalysisData, {
                organizationUserId: this.$route.query.organizationUserId,
                userCourseId: this.$route.query.userCourseId || undefined,
                abilityId: this.abilityIcon[this.activeAbility].abilityId
            }).then(res => {
                this.spinning = false;
                if (res.status) {
                    this.analysisData = res.data;
                    if (!res.data.performance) {
                        this.analysisData.performance = {}
                    }
                    if (Array.isArray(this.analysisData.dimensionals)) {
                        this.analysisData.dimensionals = this.analysisData.dimensionals.filter((f, i) => i < 3);
                    } else {
                        this.analysisData.dimensionals = [];
                    }
                    this.$nextTick(() => {
                        this.initSoreChart(res.data.abilityScore);
                        this.initRadar(this.analysisData.dimensionals);
                    })
                } else {
                    this.$toast(res.message);
                }
            })
        },
        abilityClick(index) {
            this.activeAbility = index;
            this.getAnalysisData();
            //this.initRadar();
        },
        initSoreChart(number) {
            new SoreChart({
                id: "char-box",
                number: number < 50 ? 0 : number - 50,
                arcWidth: 40,
                soreBack: this.abilityIcon[this.activeAbility].colors[0],
                soreColor: this.abilityIcon[this.activeAbility].colors[1],
                roundColor: this.abilityIcon[this.activeAbility].colors[2]
            });
        },
        initRadar(val) {
            if (!Array.isArray(val) || val.length < 3) {
                return
            }
            const numbers = val.map(m => m.score);
            const radarOption = {
                color: [this.abilityIcon[this.activeAbility].colors[1], this.abilityIcon[this.activeAbility].colors[0]],
                legend: {
                    bottom: 10
                },
                radar: [
                    {
                        indicator: [
                            {name: '语义记忆', min: 50, max: 150},
                            {name: '联系记忆', min: 50, max: 150},
                            {name: '短时记忆', min: 50, max: 150},
                        ],
                        //center: ['100%', '100%'],
                        radius: "50%",
                        startAngle: 90,
                        splitNumber: 4,
                        shape: 'circle',
                        axisName: {
                            color: this.abilityIcon[this.activeAbility].colors[2],
                            formatter: function (value, data) {
                                return `{a|${value}}{b|\n129}`
                            },
                            rich: {
                                b: {
                                    padding: [3, 0, 0, 0],
                                    align: "center"
                                },
                                /*
                                b: {
                                    padding: [3, 8],
                                    color: '#F5B03E',
                                    backgroundColor: "rgba(249, 215, 152, 0.3)",
                                    borderRadius: 4,
                                    textShadowOffsetX: 50
                                }*/
                            }
                        },
                        splitArea: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: this.abilityIcon[this.activeAbility].colors[0]
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: this.abilityIcon[this.activeAbility].colors[0]
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: 'radar',
                        symbolSize: 0,
                        lineStyle: {
                            color: "transparent"
                        },
                        data: [
                            {
                                value: [100, 100, 100],
                                name: '同龄水平',
                                areaStyle: {
                                    color: this.abilityIcon[this.activeAbility].colors[0]
                                }
                            },
                            {
                                value: numbers,
                                name: '你的表现',
                                areaStyle: {
                                    color: this.abilityIcon[this.activeAbility].colors[1]
                                }
                            }
                        ]
                    }
                ]
            };
            const myChart = this.$echarts.init(this.$refs.radar, {}, {renderer: "svg"});
            myChart.setOption(radarOption);
        },

        // 生成跳转小程序按钮和跳转事件
        initWeappHtml(val) {
            let script = document.createElement('script');
            script.type = 'text/wxtag-template';
            script.text = `<div style="width: 100%;min-height: 32px;"></div>`;
            val.launchHtml = `<wx-open-launch-weapp class="launch-btn"
                                style="display: inline-block; width: 100%;min-height: 32px;border: 1px solid transparent;overflow: hidden;"
                                username="gh_78a14dba59c6"
                                path="${val.spuPath}"
                              >${script.outerHTML}</wx-open-launch-weapp>`;
        },
        weapp() {
            this.$http.get(Apis.WeiXin.getSignature, {
                appId: "wx5c98208d08d28388",
                url: window.location.href.split("#")[0],
            }).then(res => {
                if (res.status) {
                    console.log(window.wx.config);
                    window.wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: "wx5c98208d08d28388", // 必填，公众号的唯一标识
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: ['wx-open-launch-weapp', "onMenuShareTimeline"], // 必填，需要使用的JS接口列表
                        openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-weapp']
                    });
                    window.wx.ready(function () {
                        console.log("成功");
                    });
                    window.wx.error(res => {
                        console.log('fail', res.detail);
                    });
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
.ability-box {
    padding: 86px 20px 20px;
    height: 100%;
    overflow-y: auto;
}

.sw {
    --color: #4285f4;
    --backColor1: rgba(66, 133, 244, 0.1);
    --backColor3: rgba(66, 133, 244, 0.3);
    --backColor5: rgba(66, 133, 244, 0.5);

    .score {
        background-image: url("../../assets/academic/back/aca-back6.png");
    }
}

.jy {
    --color: rgb(52, 168, 83);
    --backColor1: rgba(52, 168, 83, 0.1);
    --backColor3: rgba(52, 168, 83, 0.3);
    --backColor5: rgba(52, 168, 83, 0.5);

    .score {
        background-image: url("../../assets/academic/back/aca-back5.png");
    }
}

.zy {
    --color: rgb(251, 188, 5);
    --backColor1: rgba(251, 188, 5, 0.1);
    --backColor3: rgba(251, 188, 5, 0.3);
    --backColor5: rgba(251, 188, 5, 0.5);

    .score {
        background-image: url("../../assets/academic/back/aca-back4.png");
    }
}

.fy {
    --color: rgb(182, 18, 133);
    --backColor1: rgba(182, 18, 133, 0.1);
    --backColor3: rgba(182, 18, 133, 0.3);
    --backColor5: rgba(182, 18, 133, 0.5);

    .score {
        background-image: url("../../assets/academic/back/aca-back7.png");
    }
}

.zk {
    --color: rgb(234, 67, 53);
    --backColor1: rgba(234, 67, 53, 0.1);
    --backColor3: rgba(234, 67, 53, 0.3);
    --backColor5: rgba(234, 67, 53, 0.5);

    .score {
        background-image: url("../../assets/academic/back/aca-back8.png");
    }
}

.ability-icon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99;
}

.ability-icon ul {
    margin: 10px auto;
    width: 280px;
    display: flex;
    justify-content: space-between;
    text-align: center;

    li {
        opacity: 0.4;
    }

    li.active {
        opacity: 1;
    }

    div {
        border: 2px solid transparent;
        border-radius: 16px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        width: 32px;
        height: 32px;
    }

    p {
        color: #363F44;
        font-size: 10px;
        margin-top: 6px;
    }
}

.score {
    height: 307px;
    background-color: var(--backColor1);
    background-size: 100% 100%;
    border-radius: 16px;
    position: relative;

    .text {
        width: 88%;
        padding: 12px;
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        background: var(--backColor1);
        border-radius: 8px;
        font-size: 12px;
        line-height: 20px;
        color: var(--color);
    }
}

.char {
    width: 230px;
    height: 120px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);

    #char-box {
        width: 100%;
        height: 100%;
    }

    .char-info {
        position: absolute;
        top: 43px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        p {
            font-size: 12px;
            color: #8C8C8C;
        }

        h3 {
            font-size: 32px;
            color: #000;
            font-weight: 600;
        }

        span {
            font-size: 12px;
            color: #FF655C;
            position: absolute;
            bottom: 8px;
            right: -25px;
        }

        img {
            vertical-align: inherit;
        }
    }
}

h2 {
    line-height: 17px;
    border-left: 7px solid var(--color);
    font-size: 16px;
    color: #000;
    padding-left: 7px;
    font-weight: 600;
}

.tab-cont {
    min-height: 120px;
    background: var(--backColor1);
    border-radius: 16px;
    margin-bottom: 17px;
    padding: 17px;

    .cont {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #363F44;
        margin-top: 16px;
    }
}

.tabs {
    margin-top: 15px;

    /deep/ .van-tabs__line {
        display: none;
    }

    /deep/ .van-tab {
        font-size: 16px;
        color: #979797;
        padding: 8px;
    }

    /deep/ .van-tab--active {
        font-size: 18px;
        color: #000;
        font-weight: bold;
    }
}

.normal {
    margin-top: 55px;
    position: relative;

    .normal-char {
        width: 100%;
        position: relative;
        z-index: -1;
    }

    .line {
        height: calc(100% + 20px);
        position: absolute;
        left: 0;
        bottom: 9%;
        background: url("../../assets/academic/aca-dashed.png");
        background-size: 100% 100%;
        width: 1.5px;
        z-index: 9;

        img {
            width: 26px;
            height: 26px;
            position: absolute;
            left: -13px;
            z-index: 10;
        }

        .info {
            border-radius: 4px;
            background: var(--backColor3);
            font-size: 10px;
            color: var(--color);
            line-height: 14px;
            padding: 3px 10px;
            position: absolute;
            left: 20px;
            top: 5px;
            white-space: nowrap;
        }

        .left {
            left: auto;
            right: 20px;
        }
    }

    ul {
        display: flex;
        color: #fff;
        font-size: 10px;
        text-align: center;
        margin-top: 13px;
        position: relative;

        li {
            padding: 3px 0;
        }

        li:nth-of-type(1) {
            background: #F9D798;
            width: 23.5%;
            opacity: 0.6;
        }

        li:nth-of-type(2) {
            width: 17.5%;
            opacity: 0.7;
        }

        li:nth-of-type(3) {
            width: 18%;
            opacity: 0.8;
        }

        li:nth-of-type(4) {
            width: 18%;
            opacity: 0.9;
        }

        li:nth-of-type(5) {
            width: 23%;
        }

        .triangle {
            padding: 0;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-top: 9px solid var(--color);
            position: absolute;
            bottom: 13px;
            left: 0;
            transform: translateX(-50%);
        }
    }
}

.radar {
    width: 300px;
    height: 250px;
    position: relative;
    margin: 0 auto;

    .radar-box {
        width: 250px;
        height: 250px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .radar-text {
        font-size: 10px;
        color: var(--color);
        background: var(--backColor1);
        border-radius: 4px;
        padding: 3px 8px;
        position: absolute;
    }

    .text1 {
        top: 22px;
        right: 7px;
    }

    .text2 {
        right: -2px;
        top: 180px;
    }

    .text3 {
        left: -2px;
        top: 180px;
    }
}

.semantics {
    margin-top: 18px;

    h3 {
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    .progress {
        display: flex;
        align-items: center;
        margin-top: 8px;
        color: var(--color);
        font-size: 14px;

        .line {
            flex-grow: 1;
            height: 16px;
            position: relative;
            border-radius: 4px;
            background: var(--backColor3);
            margin-right: 10px;

            div {
                width: 20%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: var(--color);
                border-radius: 4px;
                transition: all 0.33s linear;
            }
        }

        span {
            flex-shrink: 0;
        }
    }
}

.detailed-analysis {
    padding: 17px 0;

    h2 {
        margin: 0 17px;
    }

    .carousel-item {
        padding: 0 17px;
    }

    /deep/ .van-swipe__indicators {
        display: none;
    }
}

.no-course {
    text-align: center;
    margin-top: 35px;

    h3 {
        font-size: 16px;
        color: #979797;
    }

    p {
        color: #D8D8D8;
        font-size: 14px;
        margin: 10px 0 30px;
    }
}
</style>