/**
 * zxp
 *
 * 页面请求的接口列表
 */
export default {
    User: {
        // 获取用户信息
        getUserInfo: "/user/user/GetUserInfo",
    },
    Kindergarten: {
        // 获取单次测评基础能力画像对比
        getUserCourseAbilityPortrait: "/kindergarten/UserAbilityPortrait/GetUserCourseAbilityPortrait",

        // 获取用户能力分析数据
        getAnalysisData: "/kindergarten/AbilityAnalysis/GetAnalysisData",

        // 根据课程获取游戏列表
        getGameList: "/kindergarten/UserCourse/GetAssessCourseConetntDetail"

    },
    Game: {
        // 提交游戏数据
        sendContentResult: "/datareceive/ReceiveData/SendContentResult",

        // 获取视频
        getVideo: "/content/Content/GetPlayAuth",

        // 更新视频内容信息
        updateCompletionTimes: "/coursecenter/UserChildCourse/UpdateCompletionTimes",
    },
}